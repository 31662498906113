<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <div class="filter d-flex align-center justify-center px-10">
      <div class="d-flex align-center mr-8">
        <span class="mr-2">
          Empresas:
        </span>
        <v-autocomplete
          v-model="filters.companies"
          :items="companyItems"
          multiple
          placeholder="Todas las empresas"
          class="range-field"
        />
      </div>
    </div>
    <material-card icon="mdi-clipboard-text" icon-small color="accent">
      <v-data-table
        :headers="table.headers"
        :item-class="classes"
        :items="resultData"
        :items-per-page="5000"
        disable-sort
        :footer-props="{
          'disable-items-per-page': true,
          'items-per-page-text': '',
        }"
      />
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </material-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import companies from '../companies'
  const RUTA_SERVIDOR = 'https://www.financetradebio.com'
  export default {
    name: 'RegularTablesView',
    data() {
      return {
        dialog: false,
        table: {
          headers: [
            { text: 'Symbol', value: 'ticker' },
            { text: 'Stock Price', value: 'stockPrice' },
            { text: 'RSI 14', value: 'rsi14' },
            { text: 'SMA 10', value: 'sma10' },
            { text: 'SMA 20', value: 'sma20' },
            { text: 'SMA 50', value: 'sma50' },
            { text: 'SMA 200', value: 'sma200' },
            { text: 'MACD', value: 'macd' },
            { text: 'MACD SIGNAL', value: 'macdSignal' },
            { text: 'MACD HIST', value: 'macdHist' },
          ],
        },
        filters: {
          companies: [],
        },
        responseData: [],
        loading: false,
        companies,
        companyItems: [],
        data: [],
        tickerErrors: [],
        firstCalc: false,
        smaRsiData: {},
      }
    },
    computed: {
      resultData() {
        if (this.filters.companies.length) {
          return this.data.filter(({ ticker }) => {
            return this.filters.companies.includes(ticker)
          })
        }
        return this.data
      },
    },
    async mounted() {
      const data = await this.getData()
    },
    methods: {
      classes(e) {
        var result = ''
        if (e.par) {
          result = result += ' strike-column'
        }
        if (e.gn) {
          result = result += ' gn'
        }
        return result
      },
      async getData() {
        this.loading = true
        var result = []
        const data = await axios.get(`${RUTA_SERVIDOR}/smaRsi`).then(res => res.data.data)
        this.companyItems = data.reduce((total, current) => {
          if (current.ticker) {
            const isExist = total.some(x => x.value === current.ticker)
            if (!isExist) {
              total.push({ value: current.ticker, text: current.ticker })
            }
          }
          return total
        }, [])
        this.responseData = data
        this.data = data
        this.loading = false
      },
    },
    created() {
      const token = localStorage.getItem('token')
      if (!token) {
        window.location.href = '/login'
      }
    },
  }
</script>
<style lang="scss">
  .modal {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    align-items: center;
    span.tt {
      margin-right: 25px;
    }
  }
  .companies-error {
    ul {
      display: flex;
      list-style: none;
      flex-wrap: wrap;
      padding: 0;
      padding-top: 10px;
      li {
        padding-right: 10px;
        font-size: 18px;
        color: #000;
        font-weight: normal;
      }
    }
  }
  .range-field {
    flex: none;
    padding: 0;
    .v-text-field__details {
      display: none !important;
    }
  }
  .gn td {
    color: red !important;
  }
  .strike-column {
    background: #eaebec;
  }
  .v-data-footer__select .v-input {
    display: none !important;
  }
</style>
